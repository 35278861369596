import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "purchaseOrder",
        "shippingMethod",
        "freightCosts",
        "lineItems",
        "lineItem",
        "noProducts",
        "subtotal",
        "factorySubtotal",
        "salesTax",
        "factorySalesTax",
        "total",
        "factoryTotal",
        "form",
        "ship",
        "willCall",
        "tooltip",
    ]

    connect() {
        console.log("New Factory Direct Order controller connected")
    }

    selectEmail() {
        this.purchaseOrderTarget.classList.remove("hidden")
    }

    selectPhone() {
        this.purchaseOrderTarget.classList.add("hidden")
    }

    selectAddress(event) {
        this.fetchFreightCosts()
    }

    addProduct(event) {
        event.preventDefault()
        $(this.tooltipTarget).addClass("hidden")

        const closestElement = $(event.currentTarget).closest('.line_item')
        const onePerOrder = $(closestElement).data("one-per-order")

        const quantity = $(closestElement).find(".quantity-field").val().length ? parseInt($(closestElement).find(".quantity-field").val()) : 0
        if (quantity === 0) {
            this.showTooltip("Quantity must be greater than 0", '<i class="fas fa-circle-xmark"></i>')
            return
        }
        const onePerOrderProduct = $(this.lineItemsTarget).find(`[data-one-per-order='${onePerOrder}']`);
        const isOnePerOrder = $(onePerOrderProduct).data('one-per-order-value');
        if (isOnePerOrder && onePerOrderProduct.length) {
            $(this.tooltipTarget).addClass("hidden")
            this.showTooltip("Cannot add more than 1 product from this category.", '<i class="fas fa-circle-xmark"></i>')
            return
        }

        const productAlreadyAdded = $(this.lineItemsTarget).find(`[data-identifier='${$(closestElement).attr("data-identifier")}']`);
        if (productAlreadyAdded.length) {
            $(this.tooltipTarget).addClass("hidden")
            this.showTooltip("Product already added", '<i class="fas fa-circle-xmark"></i>')
            return
        }
        this.hideNoProductsMessage()
        this.copyProductElement(event.currentTarget)
        this.clearProductSearch()
        this.clearProductResults()
        this.recalculateTotals()
        this.fetchFreightCosts()
    }

    removeProduct(event) {
        $(event.currentTarget).closest('.line_item').remove()
        this.recalculateTotals()
        this.possiblyShowNoProductsMessage()
        this.fetchFreightCosts()
    }

    hideNoProductsMessage() {
        this.noProductsTarget.classList.add("hidden")
    }

    possiblyShowNoProductsMessage() {
        if (!this.lineItemTargets.length) {
            this.noProductsTarget.classList.remove("hidden")
        }
    }

    copyProductElement(productElement) {
        $(productElement).closest('.line_item').clone().appendTo(this.lineItemsTarget)
    }

    clearProductSearch() {
        // console.log("Clear product search")

        $("#product-search").val("")
    }

    clearProductResults() {
        // console.log("Clear product results")

        $("#product-search-results").html("")
    }

    recalculateTotals() {
        // console.log("Recalculating order total")

        let subtotal = 0.0
        let factorySubtotal = 0.0
        let salesTax = 0.0
        let factorySalesTax = 0.0

        $(this.lineItemsTarget).find(".line_item").each((i, el) => {
            let isDiscountable = $(el).data("factory-line-item-discountable-value");
            let priceModifier = isDiscountable ? -1 : 1

            let quantity = $(el).find(".quantity-field").val().length ? parseInt($(el).find(".quantity-field").val()) : 0

            let price = $(el).find(".price-field").val().length ? priceModifier * parseFloat($(el).find(".price-field").val()) : 0.0
            subtotal += (quantity * price)

            let factoryPrice = $(el).find(".factory-price-field").val().length ? priceModifier * parseFloat($(el).find(".factory-price-field").val()) : 0.0
            factorySubtotal += (quantity * factoryPrice)
        })

        if (this.data.get("sales-tax") == "true") {
            let sales_tax = this.data.get("sales-tax-value")
            salesTax = subtotal * sales_tax
            factorySalesTax = factorySubtotal * sales_tax
        }

        this.subtotalTarget.innerHTML = subtotal.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
        this.factorySubtotalTarget.innerHTML = factorySubtotal.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
        this.salesTaxTarget.innerHTML = salesTax.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
        this.factorySalesTaxTarget.innerHTML = factorySalesTax.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
        this.totalTarget.innerHTML = (subtotal + salesTax).toLocaleString('en-US', {style: 'currency', currency: 'USD'});
        this.factoryTotalTarget.innerHTML = (factorySubtotal + factorySalesTax).toLocaleString('en-US', {style: 'currency', currency: 'USD'});
    }

    submit(event) {
        // console.log("Checking if trucking options are necessary")

        event.preventDefault()

        fetch(this.data.get('trucking-options-url'), {
            method: "POST",
            body: $(this.formTarget).serialize(),
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
            .then(response => {
                console.log("Checked if order needs shipping options:", response)
                return response
            })
            .then(response => {
                console.log("Response status: ", response.status)

                switch (response.status) {
                    case 200:
                        // console.log("Submitting form")
                        $("#new_factory_order").submit()
                        break
                    case 400:
                        // console.log("Showing form with errors")
                        response.text().then(text => {
                            $("#new_factory_order").replaceWith(text)
                        })
                        break
                    case 428:
                        // console.log("Showing shipping options modal")
                        response.text().then(text => {
                            $("#shipping_options_modal").modal()
                        })
                        break
                }
            })
    }

    showShip(event) {
        event.preventDefault()

        // console.log("Showing ship")
        this.shipTarget.style.display = "block"
        this.hideWillCall()
    }

    hideWillCall() {
        // console.log("Hide will call")
        this.willCallTarget.style.display = "none"
    }

    showWillCall(event) {
        event.preventDefault()

        // console.log("Showing will call")
        this.willCallTarget.style.display = "block"
        this.hideShip()
    }

    hideShip() {
        // console.log("Hide ship")
        this.shipTarget.style.display = "none"
    }

    fetchFreightCosts() {
        let url = this.data.get('freight-costs-url')

        fetch(url, {
            method: "POST",
            body: $(this.formTarget).serialize(),
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        })
            .then(response => {
                return response
            })
            .then(response => response.text())
            .then(html => {
                this.freightCostsTarget.innerHTML = html
            })
    }


    closeNotification(event) {
        event.preventDefault()
        $(this.tooltipTarget).addClass("hidden")
    }

    showTooltip(message, icon) {
        this.setTooltipMessage(message)
        this.setTooltipIcon(icon)
        $(this.tooltipTarget).removeClass("hidden")
    }

    setTooltipMessage(message) {
        $(this.tooltipTarget).find(".tooltip-message").html(message)
    }

    setTooltipIcon(icon) {
        $(this.tooltipTarget).find(".tooltip-icon").html(icon)
    }
}
