import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "quantity",
        "price",
        "total"
    ]

    static values = { discountable: Boolean }


    validatePositive(event) {
        event.target.value = event.target.value.replace(/[^0-9.]/g, '')
        const value = parseFloat(event.target.value)

        if (value < 0 || isNaN(value)) {
            event.target.value = 0;
        }
    }

    connect() {
        console.log("Line Item controller connected")
    }

    recalculateTotal(event) {
        console.log("Updating line item quantity")

        let quantity = this.quantityTarget.value.length ? parseInt(this.quantityTarget.value) : 0
        let priceModifier = this.discountableValue ? -1 : 1
        let price = this.priceTarget.value.length ? priceModifier * parseFloat(this.priceTarget.value) : 0.0
        console.log(price)
        this.totalTarget.innerHTML = (quantity * price).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
    }
}
