import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["submitButton", "kindResidential", "kindConstruction", "appointmentTrue", "appointmentFalse", "liftGateTrue", "liftGateFalse"]

    connect() {
        console.log('Factory Shipping Options controller connected')
    }

    changeKind() {
        console.log('Changed address type')

        if (this.hasSelectedResidentialOrConstruction()) {
            console.log('Selected residential')
            this.selectAppointmentRequired()
            this.disableAppointment()
        } else {
            if (this.hasLiftGateFalseOrEmpty()) {
                console.log('Selected commercial and no/empty lift gate')
                this.enableAppointment()
            }
            // otherwise do nothing
        }

        this.toggleSubmitButton()
    }

    hasSelectedResidentialOrConstruction() {
        console.log('Checking to see if user selected Residential')

        if (this.kindResidentialTarget.checked || this.kindConstructionTarget.checked) {
            console.log('Residential is true')
            return true
        }
    }

    selectAppointmentRequired() {
        console.log('Selecting appointment required')
        this.appointmentTrueTarget.checked = true
    }

    disableAppointment() {
        this.appointmentTrueTarget.setAttribute("disabled", "disabled")
        this.appointmentFalseTarget.setAttribute("disabled", "disabled")
    }

    enableAppointment() {
        this.appointmentTrueTarget.removeAttribute("disabled")
        this.appointmentFalseTarget.removeAttribute("disabled")
    }

    deselectAppointmentRequired() {
        console.log('Deselecting appointment required')
        this.appointmentTrueTarget.checked = false
    }

    changeAppointmentRequired() {
        console.log('Changing appointment required')
        this.toggleSubmitButton()
    }

    changeLiftGateRequired() {
        console.log('Changing lift gate required')
        if (this.liftGateTrueTarget.checked) {
            if (!this.hasSelectedResidentialOrConstruction()) {
                console.log('Lift gate required on commercial, disabling appointment selector and requiring appointment.')
                this.selectAppointmentRequired()
                this.disableAppointment()
            }
            // else do nothing, appointment already required
        }
        if (this.liftGateFalseTarget.checked) {
            if (!this.hasSelectedResidentialOrConstruction()) {
                console.log('Lift gate is *not* required on commercial, enabling appointment selector.')
                this.enableAppointment()
            }
        }
        this.toggleSubmitButton()
    }

    hasLiftGateFalseOrEmpty() {
        if (this.liftGateTrueTarget.checked) {
            return false
        } else {
            // liftGateFalse must be selected or no selection
            return true
        }
    }

    toggleSubmitButton() {
        console.log('Possibly enabling submit')
        console.log('Kind: ' + this.kindValue())
        console.log('Appointment: ' + this.appointmentValue())
        console.log('Lift Gate: ' + this.liftGateValue())

        if (this.kindValue() && this.appointmentValue() && this.liftGateValue()) {
            console.log('All items dealt with; enabling submit')
            this.submitButtonTarget.removeAttribute("disabled")
        } else {
            console.log('Not all items dealt with; disabling submit')
            this.submitButtonTarget.setAttribute("disabled", "disabled")
        }
    }

    kindValue() {
        return $("input[name='address_kind']:checked").val()
    }

    appointmentValue() {
        return $("input[name='delivery_appointment']:checked").val()
    }

    liftGateValue() {
        return $("input[name='lift_gate']:checked").val()
    }

    submit() {
        console.log('User clicked submit')
        this.persistValuesToOrderForm()
        this.submitOrderForm()
    }

    persistValuesToOrderForm() {
        console.log('Writing values to order form')
        $('#factory_order_address_kind').val(this.kindValue())
        $('#factory_order_delivery_appointment').val(this.appointmentValue())
        $('#factory_order_lift_gate').val(this.liftGateValue())
    }

    submitOrderForm() {
        console.log('Submitting order form')
        $('#new_factory_order').submit()
    }
}
