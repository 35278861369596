import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "quantity",
        "price",
        "factoryPrice",
        "total",
        "factoryTotal"
    ]

    static values = { discountable: Boolean }

    connect() {
        console.log("Factory Line Item controller connected")
    }

    validatePositive(event) {
        event.target.value = event.target.value.replace(/[^0-9.]/g, '')
        const value = parseFloat(event.target.value)

        if (value < 0 || isNaN(value)) {
            event.target.value = 0;
        }
    }


    recalculateTotal(event) {
        let quantity = this.quantityTarget.value.length ? parseInt(this.quantityTarget.value) : 0
        let priceModifier = this.discountableValue ? -1 : 1
        let price = this.priceTarget.value.length ? priceModifier * parseFloat(this.priceTarget.value) : 0.0
        this.totalTarget.innerHTML = (quantity * price).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
    }

    recalculateFactoryTotal(event) {
        let quantity = this.quantityTarget.value.length ? parseInt(this.quantityTarget.value) : 0
        let priceModifier = this.discountableValue ? -1 : 1
        let factoryPrice = this.factoryPriceTarget.value.length ? priceModifier * parseFloat(this.factoryPriceTarget.value) : 0.0
        this.factoryTotalTarget.innerHTML = (quantity * factoryPrice).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        })
    }
}
